<template>
  <v-dialog v-model="show" persistent width="500px">
    <!-- ALERT -->
    <v-card v-if="type == 'alert'">
      <v-toolbar dark flat>
        <v-toolbar-title>{{title}}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4">
        <h3 class="font-weight-medium text--primary">{{message}}</h3>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          text
          v-on:click="() => $emit('handle')"
        >Aceptar</v-btn>
      </v-card-actions>
    </v-card>
    <!-- CONFIRM -->
    <v-card v-else-if="type == 'confirm'">
      <v-toolbar dark flat>
        <v-toolbar-title>{{title}}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4">
        <h3 class="font-weight-medium text--primary">{{message}}</h3>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="danger"
          text
          v-on:click="() => show = false"
        >Cancelar</v-btn>
        <v-btn
          color="primary"
          text
          v-on:click="() => $emit('handle')"
        >Aceptar</v-btn>
      </v-card-actions>
    </v-card>
    <!-- LOADING -->
    <v-card v-else-if="type == 'loading'" class="pa-4">
      <v-card-text class="text-center pt-4">
        <h2 class="font-weight-medium text--primary">{{message}}</h2>
      </v-card-text>
      <v-card-text>
        <v-progress-linear indeterminate></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import GlobalDialogPlugin from './dialog.js'

  export default {
    data() {
      return {
        show: false,
        type: null,
        title: null,
        message: null
      }
    },
    created() {
      GlobalDialogPlugin.EventBus.$on('alert', params => {
        this.$off('handle')
        this.$on('handle', () => {this.show = false; params.callback()})
        this.type = 'alert'
        this.title = params.title
        this.message = params.message
        this.show = true
      })
      GlobalDialogPlugin.EventBus.$on('confirm', params => {
        this.$off('handle')
        this.$on('handle', () => {this.show = false; params.callback()})
        this.type = 'confirm'
        this.title = params.title
        this.message = params.message
        this.show = true
      })
      GlobalDialogPlugin.EventBus.$on('loading', params => {
        this.$off('handle')
        this.type = 'loading'
        this.message = params.message
        this.show = true
      })
      GlobalDialogPlugin.EventBus.$on('hide', () => {
        this.$off('handle')
        this.show = false
      })
    }
  }
</script>
