<template>
  <v-card>
    <v-toolbar color="secondary">
      <v-btn icon v-on:click="close" color="white">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <template v-if="tab == 0">
        <v-btn v-if="cliente_id" icon color="danger" v-on:click="remove">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn icon v-on:click="save" color="white">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </template>
      <template v-if="tab == 1">
        <v-btn icon v-on:click="addContacto" color="white">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <template v-if="tab == 2">
        <v-btn icon v-on:click="addDireccion" color="white">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <template v-if="tab == 3">
        <v-btn icon color="white" v-on:click="downloadPDFEstadoCuenta">
          <v-icon>mdi-file-pdf-box</v-icon>
        </v-btn>
        <v-btn icon color="white" v-on:click="downloadExcelEstadoCuenta">
          <v-icon>mdi-microsoft-excel</v-icon>
        </v-btn>
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              color="white"
            >
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item>
                <v-menu
                  v-model="menuFechaInicialEstadoCuenta"
                  v-bind:close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">    
                    <v-text-field
                      color="light"
                      v-bind:value="fechaInicialEstadoCuenta | moment('DD/MM/YYYY')"
                      prepend-inner-icon="mdi-calendar"
                      label="Fecha Inicial"
                      readonly
                      v-on="on"
                      v-bind="attrs"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaInicialEstadoCuenta"
                    v-on:input="menuFechaInicialEstadoCuenta = false"
                  ></v-date-picker>
                </v-menu>
              </v-list-item>
              <v-list-item>
                <v-menu
                  v-model="menuFechaFinalEstadoCuenta"
                  v-bind:close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">    
                    <v-text-field
                      color="light"
                      v-bind:value="fechaFinalEstadoCuenta | moment('DD/MM/YYYY')"
                      prepend-inner-icon="mdi-calendar"
                      label="Fecha Inicial"
                      readonly
                      v-on="on"
                      v-bind="attrs"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaFinalEstadoCuenta"
                    v-on:input="menuFechaFinalEstadoCuenta = false"
                  ></v-date-picker>
                </v-menu>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="danger" text v-on:click="clearFilter">Limpiar</v-btn>
              <v-btn color="primary" text v-on:click="loadEstadoCuenta">Aplicar</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-btn icon v-on:click="loadEstadoCuenta" color="white">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </template>
    </v-toolbar>
    <v-tabs v-if="params.sku" v-model="tab" fixed-tabs show-arrows>
      <v-tab>Detalle</v-tab>
      <v-tab>Impuestos</v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card class="pa-4">
          <v-card-text>
            <v-form ref="form" v-model="formValid">
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="sku"
                    label="Sku"
                    validate-on-blur
                    v-bind:readonly="!!params.sku"
                    v-bind:rules="requiredRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="codigo_ean"
                    label="Código EAN"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="marca"
                    label="Marca"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="modelo"
                    label="Modelo"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="descripcion"
                    label="Descripción"
                    validate-on-blur
                    v-bind:rules="requiredRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-autocomplete
                    v-model="unidad_id"
                    v-bind:items="unidades"
                    label="Unidad"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-autocomplete
                    v-model="esquema_impuestos_id"
                    v-bind:items="esquemas_impuestos"
                    label="Esquema Impuestos"
                    validate-on-blur
                    v-bind:rules="requiredRules"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-autocomplete
                    v-model="clave_prod_ser_sat"
                    v-bind:items="claves_sat"
                    label="Clave SAT"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="estatus"
                    v-bind:items="[
                      {text: 'Activo', value: 'A'},
                      {text: 'Inactivo', value: 'I'},
                    ]"
                    label="Estatus"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-data-table
          v-bind:headers="headersContactos"
          v-bind:items="contactos"
          v-on:click:row="row => openContacto(row.contacto_id)"
          disable-pagination
          v-bind:hide-default-footer="true"
        ></v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
  export default {
    data() {
      return {
        subComponent: null,
        subComponentParams: null,
        sku: null,
        codigo_ean: null,
        clave_prod_ser_sat: null,
        descripcion: null,
        sat_cporte_peso_en_kg: null,
        marca: null,
        modelo: null,
        unidad_id: null,
        estatus: 'A',
        esquema_impuestos_id: null,
        claves_sat: [],
        unidades: [],
        esquemas_impuestos: [],
        tab: 0,
        formValid: false,
        requiredRules: [v => !!v || 'Este campo es requerido']
      }
    },
    created() {
      // Cargar Esquemas Impuestos
      this.$reqSisnet('Lov:Lov:Lov:LoadLovFieldEsquemaImpuestos', {pageSize: 1000}, this.$store.state.session)
      .then(res => {
        this.esquemas_impuestos = res.records.map(row => ({
          text: row.descripcion,
          value: row.esquema_impuestos_id
        }))
      })

      // Cargar Unidades
      this.$reqSisnet('Lov:Lov:Lov:LoadLovFieldUnidades', {pageSize: 1000}, this.$store.state.session)
      .then(res => {
        this.unidades = res.records.map(row => ({
          text: row.descripcion,
          value: row.unidad_id
        }))
      })
      
      // Cargar Claves SAT
      this.$reqSisnet('Lov:Lov:Lov:LoadLovFieldProdServSAT', {pageSize: 100000}, this.$store.state.session)
      .then(res => {
        this.claves_sat = res.records.map(row => ({
          text: row.clave + ' - ' + row.descripcion,
          value: row.clave
        }))
      })

      this.sku = this.params.sku
      if (!this.sku === null) return

      this.load()
    },
    methods: {
      load() {
        // Cargar parte
        this.$reqSisnet('inventarios:catalogo_inventarios:catalogo_inventarios:Load', {sku: this.sku}, this.$store.state.session)
        .then(res => {
          this.sku = res.sku
          this.codigo_ean = res.codigo_ean
          this.clave_prod_ser_sat = res.clave_prod_ser_sat
          this.descripcion = res.descripcion
          this.sat_cporte_peso_en_kg = res.sat_cporte_peso_en_kg
          this.marca = res.marca
          this.modelo = res.modelo
          this.unidad_id = res.unidad_id
          this.estatus = res.estatus
          this.esquema_impuestos_id = res.esquema_impuestos_id
        })
      },
      save() {
        if (!this.$refs.form.validate()) return this.$dialog.alert('Datos invalidos!', 'Por favor verifique la información')
        this.$dialog.loading()
        // Guardar cliente
        setTimeout(this.$dialog.hide, 3000)
      },
      remove() {
        this.$dialog.confirm('Alerta', 'Desea eliminar al cliente?', () => {
          this.$dialog.loading()
          // Eliminar cliente
          setTimeout(this.$dialog.hide, 3000)
        })
      },
      downloadEquivalentes() {
       let params = {
          sku: this.sku,
        }
        window.open(this.$linkSisnet('inventarios:catalogo_inventarios:catalogo_inventarios_equivalentes:ExportExcelEquivalentes', params, this.$store.state.session))
      },
      downloadExcelEstadoCuenta() {
        let params = {
          cliente_id: this.cliente_id,
          fecha_inicial: this.$moment(this.fechaInicialEstadoCuenta).format('DD/MM/YYYY'),
          fecha_final: this.$moment(this.fechaFinalEstadoCuenta).format('DD/MM/YYYY'),
        }
        window.open(this.$linkSisnet('ventas:clientes:clientes_estado_cuenta:ExportExcelEstadoCuenta', params, this.$store.state.session))
      },
      clearFilter() {
        this.fechaInicialEstadoCuenta = this.$moment().format('YYYY-01-01')
        this.fechaFinalEstadoCuenta = this.$moment().format('YYYY-MM-DD')
        this.loadEstadoCuenta()
      },
      close() {
        this.$parent.subComponent = null
      }
    },
    props: ['params']
  }
</script>