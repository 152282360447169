import GlobalDialog from './dialog.vue'

const GlobalDialogPlugin = {
  install(Vue) {
    this.EventBus = new Vue()
    Vue.component('global-dialog', GlobalDialog)
    Vue.prototype.$dialog = {
      alert(title, message, callback = () => undefined) {
        let params = {
          title,
          message,
          callback
        }
        GlobalDialogPlugin.EventBus.$emit('alert', params)
      },
      confirm(title, message, callback = () => undefined) {
        let params = {
          title,
          message,
          callback
        }
        GlobalDialogPlugin.EventBus.$emit('confirm', params)
      },
      loading(message = 'Cargando...') {
        let params = {message}
        GlobalDialogPlugin.EventBus.$emit('loading', params)
      },
      hide() {
        GlobalDialogPlugin.EventBus.$emit('hide')
      }
    }
  }
}
export default GlobalDialogPlugin