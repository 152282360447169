<template>
  <v-dialog v-model="show" persistent width="500px">
    <v-card>
      <v-toolbar dark flat>
        <v-toolbar-title>{{title || 'Seleccione archivo'}}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="text-center pt-4">
        <v-file-input
          v-for="file in files"
          v-bind:key="file.name"
          v-bind:accept="file.accept || '*.*'"
          v-bind:prepend-icon="file.icon || 'mdi-file'"
          v-bind:label="file.label"
        ></v-file-input>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="danger"
          text
          v-on:click="close"
        >Cancelar</v-btn>
        <v-btn
          color="primary"
          text
          v-on:click="() => {}"
        >Enviar</v-btn>
      </v-card-actions>
    </v-card>  
  </v-dialog>
</template>
<script>
  export default {
    data() {
      return {}
    },
    methods: {
      send() {
        this.$emit('send')
      },
      close() {
        this.$emit('close')
      }
    },
    props: [
      'show',
      'title',
      'files'
    ]
  }
</script>
