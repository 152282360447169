<template>
  <v-card>
    <v-toolbar color="secondary">
      <v-spacer></v-spacer>
      <template>
        <v-btn icon v-on:click="downloadPdf" color="white">
          <v-icon>mdi-file-pdf-box</v-icon>
        </v-btn>
        <v-btn icon v-on:click="downloadExcel" color="white">
          <v-icon>mdi-microsoft-excel</v-icon>
        </v-btn>
        <v-btn icon v-on:click="downloadHTML" color="white">
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
        <v-btn icon v-on:click="clear" color="white">
          <v-icon>mdi-backspace</v-icon>
        </v-btn>
      </template>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card>
      <v-card-text>
        <v-card class="pa-4">
          <v-card-text>
            <v-form ref="form" v-model="formValid">
              <v-row>
                <v-col cols="6" sm="4">
                  <v-menu
                    v-model="menuFechaInicial"
                    v-bind:close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">    
                      <v-text-field
                        color="light"
                        v-bind:value="fecha_inicial | moment('DD/MM/YYYY')"
                        prepend-inner-icon="mdi-calendar"
                        label="Desde"
                        readonly
                        v-on="on"
                        v-bind="attrs"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fecha_inicial"
                      v-on:input="menuFechaInicial = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" sm="4">
                  <v-menu
                    v-model="menuFechaFinal"
                    v-bind:close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">    
                      <v-text-field
                        color="light"
                        v-bind:value="fecha_final | moment('DD/MM/YYYY')"
                        prepend-inner-icon="mdi-calendar"
                        label="Hasta"
                        readonly
                        v-on="on"
                        v-bind="attrs"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fecha_final"
                      v-on:input="menuFechaFinal = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" sm="4">
                  <v-autocomplete
                    v-model="cliente_id"
                    v-bind:items="clientes"
                    label="Cliente"
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="4">
                  <v-autocomplete
                    v-model="empresa_id"
                    v-bind:items="empresas"
                    label="Empresa"
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="4">
                  <v-autocomplete
                    v-model="sucursal_id"
                    v-bind:items="sucursales"
                    label="Sucursal"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <v-card class="mt-4">
          <v-card-text>
            <v-row>
              <v-col cols="8" sm="4">
                <v-select
                  return-object
                  v-model="agrupador"
                  v-bind:items="agrupadores.filter(a => !selectedAgrupadores.includes(a))"
                  label="Agrupador"
                ></v-select>
              </v-col>
              <v-col cols="4" sm="2">
                <v-btn
                  class="mt-2" 
                  fab
                  small
                  color="primary"
                  v-bind:disabled="!agrupador"
                  v-on:click="addAgrupador"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-data-table
              v-bind:headers="agrupadoresHeaders"
              v-bind:items="selectedAgrupadores"
              disable-filtering
              disable-sort
              disable-pagination
              hide-default-footer
              no-data-text="No se seleccionó agrupador"
            >
              <template v-slot:item.controls="{item}">
                <div class="text-right">
                  <v-btn icon color="primary" v-on:click="moveUpAgrupador(item)">
                    <v-icon >mdi-arrow-up-bold</v-icon>
                  </v-btn>
                  <v-btn icon color="primary" v-on:click="moveDownAgrupador(item)">
                    <v-icon >mdi-arrow-down-bold</v-icon>
                  </v-btn>
                  <v-btn icon color="danger" v-on:click="removeAgrupador(item)">
                    <v-icon >mdi-delete</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
  export default {
    data() {
      return {
        formValid: false,
        menuFechaInicial: false,
        menuFechaFinal: false,
        fecha_inicial: null,
        fecha_final: null,
        cliente_id: null,
        empresa_id: null,
        sucursal_id: null,
        agrupador: null,
        agrupadores: [
          {text: 'Cliente', value: 'corteXcliente'},
          
        ],
        agrupadoresHeaders: [
          {text: 'Agrupador', value: 'text'},
          {text: '', value: 'controls'},
        ],
        selectedAgrupadores: [],
        cliente: [],
        reportHtml: null
      }
    },
    created() {
      // Cargar clientes
      this.$reqSisnet('Lov:Lov:Lov:LoadLovFieldClientes', {pageSize: 1000}, this.$store.state.session)
      .then(res => {
        this.clientes = res.records.map(row => ({
          text: row.cliente_id + ' - ' + row.nombre,
          value: row.cliente_id
        }))
      })

      // Cargar empresa
      this.$reqSisnet('Lov:Lov:Lov:LoadLovFieldEmpresas', {pageSize: 100000}, this.$store.state.session)
      .then(res => {
        this.empresas = res.records.map(row => ({
          text: row.empresa_id + ' - ' + row.nombre,
          value: row.empresa_id
        }))
      })

      // Cargar sucursales
      this.$reqSisnet('Lov:Lov:Lov:LoadLovFieldSucursales', {pageSize: 100000}, this.$store.state.session)
      .then(res => {
        this.sucursales = res.records.map(row => ({
          text: row.sucursal_id + ' - ' + row.nombre,
          value: row.sucursal_id
  }))
})

    },
    methods: {
      downloadPdf() {
        let params = {
          fecha_inicial: this.fecha_inicial ? this.$moment(this.fecha_inicial).format('DD/MM/YYYY') : '',
          fecha_final: this.fecha_final ? this.$moment(this.fecha_final).format('DD/MM/YYYY') : '',
          cliente_id: this.cliente_id,
          empresa_id: this.empresa_id,
          sucursal_id: this.sucursal_id,
          agrupacion: this.selectedAgrupadores.map(a => a.value)          
          
          
          
        }
        window.open(this.$linkSisnet('ventas:reporte_antig_saldos_detalle:reporte_antig_saldos_detalle:ReportPdf', params, this.$store.state.session))
      },
      downloadExcel() {
        let params = {
          fecha_inicial: this.fecha_inicial ? this.$moment(this.fecha_inicial).format('DD/MM/YYYY') : '',
          fecha_final: this.fecha_final ? this.$moment(this.fecha_final).format('DD/MM/YYYY') : '',
          cliente_id: this.cliente_id,
          empresa_id: this.empresa_id,
          sucursal_id: this.sucursal_id,
          agrupacion: this.selectedAgrupadores.map(a => a.value)
        }
        window.open(this.$linkSisnet('ventas:reporte_antig_saldos_detalle:reporte_antig_saldos_detalle:ReportExcel', params, this.$store.state.session))
      },
      downloadHTML() {
        let params = {
          fecha_inicial: this.fecha_inicial ? this.$moment(this.fecha_inicial).format('DD/MM/YYYY') : '',
          fecha_final: this.fecha_final ? this.$moment(this.fecha_final).format('DD/MM/YYYY') : '',
          cliente_id: this.cliente_id,
          empresa_id: this.empresa_id,
          sucursal_id: this.sucursal_id,
          agrupacion: this.selectedAgrupadores.map(a => a.value)
        }
        this.$dialog.loading()
        this.$reqSisnet('ventas:reporte_antig_saldos_detalle:reporte_antig_saldos_detalle:ReportHTML', params, this.$store.state.session)
        .then(res => {
          let win = window.open('about:blank')
          let css = `
            <style>
              .TableReport {
                margin:0px;
                padding:0px;
                width:100%;
                border:0px solid #EEEEEE;
                
                -moz-border-radius-bottomleft:0px;
                -webkit-border-bottom-left-radius:0px;
                border-bottom-left-radius:0px;
                
                -moz-border-radius-bottomright:0px;
                -webkit-border-bottom-right-radius:0px;
                border-bottom-right-radius:0px;
                
                -moz-border-radius-topright:0px;
                -webkit-border-top-right-radius:0px;
                border-top-right-radius:0px;
                
                -moz-border-radius-topleft:0px;
                -webkit-border-top-left-radius:0px;
                border-top-left-radius:0px;
              }

              .TableReport table{
                  border-collapse: collapse;
                      border-spacing: 0;
                width:100%;
                margin:0px;padding:0px;
              }

              .TableReport tr:last-child td:last-child {
                -moz-border-radius-bottomright:0px;
                -webkit-border-bottom-right-radius:0px;
                border-bottom-right-radius:0px;
              }

              .TableReport table tr:first-child td:first-child {
                -moz-border-radius-topleft:0px;
                -webkit-border-top-left-radius:0px;
                border-top-left-radius:0px;
              }

              .TableReport table tr:first-child td:last-child {
                -moz-border-radius-topright:0px;
                -webkit-border-top-right-radius:0px;
                border-top-right-radius:0px;
              }

              .TableReport tr:last-child td:first-child{
                -moz-border-radius-bottomleft:0px;
                -webkit-border-bottom-left-radius:0px;
                border-bottom-left-radius:0px;
              }

              .TableReport tr:hover td{
                background-color:#4E78B1;
                color:#FFFFFF !important;
              }

              .TableReportx tr:nth-child(odd) { background-color:#FFFFFF; }

              .TableReportx tr:nth-child(even) { background-color:#EFEFEF; }

              .TableReport td{
                vertical-align:middle;
                
                border:1px solid #eeeeee;
                border-width:0px 1px 1px 0px;
                text-align:left;
                padding:3px;
                font-size:11px;
                font-family:Arial;
                font-weight:normal;
                color:#000000;
                text-wrap: none;
              }

              .TableReport tr:last-child td{
                border-width:0px 1px 0px 0px;
              }

              .TableReport tr td:last-child{
                border-width:0px 0px 1px 0px;
              }

              .TableReport tr:last-child td:last-child{
                border-width:0px 0px 0px 0px;
              }


              .TableReport tr:first-child td {
                background-image: url(../js/ext-3.4.0/resources/images/default/toolbar/bg.gif) !important;
                background-repeat: repeat-x;
                background-position: top left;
                height: 28px;
                padding-left:7px;
                padding-right:7px;
                
                border:0px solid #ffffff;
                text-align:center;
                border-width:0px 0px 1px 1px;
                font-size:11px;
                font-family:arial, tahoma, helvetica, sans-serif;
                color:#000000;
              }

              .CSSTableGenerator tr:first-child:hover td{
                color:#000000;
              }

              .TableReport tr:first-child td:first-child{
                border-width:0px 0px 1px 0px;
              }

              .TableReport tr:first-child td:last-child{
                border-width:0px 0px 1px 1px;
              }
            </style>`
          win.document.write(res + css)
          this.$dialog.hide()
        })
      },
      addAgrupador() {
        this.selectedAgrupadores.push(this.agrupador)
        this.agrupador = null
      },
      moveUpAgrupador(item) {
        let i = this.selectedAgrupadores.findIndex(a => a == item)
        if (i < 1) return
        this.$set(this.selectedAgrupadores, i, this.selectedAgrupadores[i - 1])
        this.$set(this.selectedAgrupadores, i - 1, item)
      },
      moveDownAgrupador(item) {
        let i = this.selectedAgrupadores.findIndex(a => a == item)
        if (i < 0 || i == this.selectedAgrupadores.length - 1) return
        this.$set(this.selectedAgrupadores, i, this.selectedAgrupadores[i + 1])
        this.$set(this.selectedAgrupadores, i + 1, item)
      },
      removeAgrupador(item) {
        this.selectedAgrupadores = this.selectedAgrupadores.filter(a => a != item)
      },
      clear() {
          this.fecha_inicial = null
          this.fecha_final = null
          this.cliente_id = null
          this.empresa_id = null
          this.sucursal_id = null
      }
    }
  }
</script>