<template>
  <component
    v-if="!!subComponent"
    v-bind:is="subComponent"
    v-bind:params="subComponentParams"
  ></component>
  <div v-else>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      right
    >
      <v-list>
        <v-list-group prepend-icon="mdi-upload" no-action>
          <template v-slot:activator>
            <v-list-item-title>Importar</v-list-item-title>
          </template>
          <v-list-item v-on:click="$dialog.alert('No disponible'); drawer = false">
            <v-list-item-title>Catálogo</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click="$dialog.alert('No disponible'); drawer = false">
            <v-list-item-title>Máximos / Mínimos</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click="$dialog.alert('No disponible'); drawer = false">
            <v-list-item-title>Localización</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click="$dialog.alert('No disponible'); drawer = false">
            <v-list-item-title>Clasificación</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click="$dialog.alert('No disponible'); drawer = false">
            <v-list-item-title>Equivalentes</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group prepend-icon="mdi-download" no-action>
          <template v-slot:activator>
            <v-list-item-title>Descargar</v-list-item-title>
          </template>
          <v-list-item v-on:click="downloadEquivalentes(); drawer = false">
            <v-list-item-title>Equivalentes</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-card>
      <v-toolbar color="secondary">
        <v-text-field
          type="search"
          clearable
          v-model="searchValue"
          v-on:keydown.enter="load"
          v-on:click:clear="() => {searchValue = ''; load()}"
          prepend-inner-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
          solo
          dense
        ></v-text-field>
        <v-btn icon v-on:click="showDrawer = true" v-bind:loading="isLoading" color="white">
          <v-icon v-on:click="load">mdi-sync</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn icon color="white" v-on:click="downloadPDF">
          <v-icon>mdi-file-pdf-box</v-icon>
        </v-btn>
        <v-btn icon color="white" v-on:click="downloadExcel">
          <v-icon>mdi-microsoft-excel</v-icon>
        </v-btn>
        <v-btn icon color="white" v-on:click="add">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn
          icon
          v-on:click="drawer = !drawer"
          color="white"
        >
          <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-data-table
        fixed-header
        v-bind:headers="headers"
        v-bind:items="data"
        v-bind:server-items-length="itemsLength"
        v-bind:options.sync="options"
        v-bind:footer-props="{'items-per-page-options': [200, 100, 50]}"
        v-bind:loading = "isLoading"
        v-on:click:row="row => open(row.sku)"
        v-on:update:options="load"
      ></v-data-table>
    </v-card>
    <FileUpload
      v-bind:show="showFileUpload"
      v-bind:files="files"
      v-on:send="upload"
      v-on:close="showFileUpload = false"
    ></FileUpload>
  </div>
</template>

<script>
  import CatalogoInventariosDetalle from './catalogo_inventarios-detalle.vue'

  import FileUpload from '../../../components/FileUpload.vue'

  export default {
    data() {
      return {
        drawer: false,
        subComponent: null,
        subComponentParams: null,
        isLoading: false,
        headers: [
          {value: 'sku', text: 'Sku'},
          {value: 'codigo_ean', text: 'Código EAN'},
          {value: 'clave_prod_ser_sat', text: 'Clave SAT'},
          {value: 'descripcion', text: 'Descripción'},
          {value: 'sat_cporte_peso_en_kg', text: 'Peso'},
          {value: 'marca', text: 'Marca'},
          {value: 'modelo', text: 'Modelo'},
          {value: 'unidad_id', text: 'Unidad'},
          {value: 'estatus', text: 'Estatus'},
          {value: 'esquema_impuestos_id', text: 'Esquema Imp.'}
        ],
        data: [],
        options: {},
        itemsLength: 0,
        searchValue: '',
        showFileUpload: false,
        files: [
          {
            name: 'archivo_catalogo',
            label: 'Catálogo',
            required: true,
            accept: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }
        ]
      }
    },
    methods: {
      load() {
        console.log(this.params)
        this.isLoading = true
        this.$reqSisnet('inventarios:catalogo_inventarios:catalogo_inventarios:Search', this.params, this.$store.state.session)
        .then(res => {
          if (!res.records) throw new Error(res.msg || 'Imposible cargar inventario')
          this.itemsLength = res.totalCount
          this.data = res.records
          this.isLoading = false
        })
        .catch(err => {
          this.isLoading = false
          this.$handleError(err)
        })
      },
      add() {
        this.subComponentParams = {}
        this.subComponent = CatalogoInventariosDetalle
      },
      open(sku) {
        this.subComponentParams = {sku}
        this.subComponent = CatalogoInventariosDetalle
      },
      uploadCatalogo() {
        
      },
      downloadPDF() {
        window.open(this.$linkSisnet('inventarios:catalogo_inventarios:catalogo_inventarios:ExportPdf', this.params, this.$store.state.session))
      },
      downloadExcel() {
        window.open(this.$linkSisnet('inventarios:catalogo_inventarios:catalogo_inventarios:ExportExcel', this.params, this.$store.state.session))
      },
      downloadEquivalentes() {
        let params = {sku: this.sku}
        window.open(this.$linkSisnet('inventarios:catalogo_inventarios:catalogo_inventarios_equivalentes:ExportExcelEquivalentes', params, this.$store.state.session))
      },
    },
    computed: {
      params() {
        let params = {
          sku: this.searchValue || '',
          pageSize: this.options.itemsPerPage,
          start: (this.options.page - 1) * this.options.itemsPerPage
        }
        if (this.options.sortBy[0]) {
          params.sort = this.options.sortBy[0],
          params.dir = this.options.sortDesc[0] ? 'DESC' : 'ASC'
        }
        return params
      }
    },
    components: {
      CatalogoInventariosDetalle,
      FileUpload
    }
  }
</script>
