<template>
  <component
    v-if="!!subComponent"
    v-bind:params="subComponentParams"
    v-bind:is="subComponent"
  ></component>
  <v-card v-else>
    <v-toolbar color="secondary">
      <v-btn icon v-on:click="close" color="white">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <template v-if="tab == 0">
        <v-btn v-if="params.cliente_id" icon color="danger" v-on:click="remove">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn icon v-on:click="save" color="white">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </template>
      <template v-if="tab == 1">
        <v-btn icon v-on:click="addContacto" color="white">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <template v-if="tab == 2">
        <v-btn icon v-on:click="addDireccion" color="white">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <template v-if="tab == 3">
        <v-btn icon color="white" v-on:click="downloadPDFEstadoCuenta">
          <v-icon>mdi-file-pdf-box</v-icon>
        </v-btn>
        <v-btn icon color="white" v-on:click="downloadExcelEstadoCuenta">
          <v-icon>mdi-microsoft-excel</v-icon>
        </v-btn>
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              color="white"
            >
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item>
                <v-menu
                  v-model="menuFechaInicialEstadoCuenta"
                  v-bind:close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">    
                    <v-text-field
                      color="light"
                      v-bind:value="fechaInicialEstadoCuenta | moment('DD/MM/YYYY')"
                      prepend-inner-icon="mdi-calendar"
                      label="Fecha Inicial"
                      readonly
                      v-on="on"
                      v-bind="attrs"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaInicialEstadoCuenta"
                    v-on:input="menuFechaInicialEstadoCuenta = false"
                  ></v-date-picker>
                </v-menu>
              </v-list-item>
              <v-list-item>
                <v-menu
                  v-model="menuFechaFinalEstadoCuenta"
                  v-bind:close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">    
                    <v-text-field
                      color="light"
                      v-bind:value="fechaFinalEstadoCuenta | moment('DD/MM/YYYY')"
                      prepend-inner-icon="mdi-calendar"
                      label="Fecha Inicial"
                      readonly
                      v-on="on"
                      v-bind="attrs"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaFinalEstadoCuenta"
                    v-on:input="menuFechaFinalEstadoCuenta = false"
                  ></v-date-picker>
                </v-menu>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="danger" text v-on:click="clearFilter">Limpiar</v-btn>
              <v-btn color="primary" text v-on:click="loadEstadoCuenta">Aplicar</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-btn icon v-on:click="loadEstadoCuenta" color="white">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </template>
    </v-toolbar>
    <v-tabs v-if="cliente_id" v-model="tab" fixed-tabs show-arrows>
      <v-tab>Cliente</v-tab>
      <v-tab>Contactos</v-tab>
      <v-tab>Direcciones</v-tab>
      <v-tab>Estado de Cuenta</v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card class="pa-4">
          <v-card-text>
            <v-form ref="form" v-model="formValid">
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="nombre"
                    label="Nombre / Razón Social"
                    validate-on-blur
                    v-bind:rules="requiredRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="rfc"
                    label="RFC"
                    validate-on-blur
                    v-bind:rules="requiredRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-autocomplete
                    v-model="corporativo_id"
                    v-bind:items="corporativos"
                    label="Corporativo"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="calle"
                    label="Calle"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="2">
                  <v-text-field
                    v-model="no_exterior"
                    label="No. Exterior"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="2">
                  <v-text-field
                    v-model="no_interior"
                    label="No. Interior"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="2">
                  <v-text-field
                    v-model="codigo_postal"
                    label="Codigo Postal"
                    validate-on-blur
                    v-bind:rules="codigoPostalRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="colonia"
                    label="Colonia"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="municipio"
                    label="Delegación / Municipio"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="localidad"
                    label="Localidad"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="estado"
                    label="Estado"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="referencia"
                    label="Referencia"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="2">
                  <v-text-field
                    v-model="dias_credito"
                    label="Dias de Crédito"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="2">
                  <v-text-field
                    v-model="limite_credito"
                    label="Límite de Crédito"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="num_cta_pago"
                    label="Num. Cta. Pago"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-autocomplete
                    v-model="lista_precios_id"
                    v-bind:items="listas_precios"
                    label="Lista de Precios"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-autocomplete
                    v-model="vendedor_id"
                    v-bind:items="vendedores"
                    label="Vendedor"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-autocomplete
                    v-model="cuenta_contable"
                    v-bind:items="cuentas_contables"
                    label="Cuenta Contable"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="num_proveedor"
                    label="Num. Proveedor"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-data-table
          v-bind:headers="headersContactos"
          v-bind:items="contactos"
          v-on:click:row="row => openContacto(row.contacto_id)"
          disable-pagination
          v-bind:hide-default-footer="true"
        ></v-data-table>
      </v-tab-item>
      <v-tab-item>
        <v-data-table
          v-bind:headers="headersDirecciones"
          v-bind:items="direcciones"
          v-on:click:row="row => openDireccion(row.direccion_id)"
          disable-pagination
          v-bind:hide-default-footer="true"
        ></v-data-table>
      </v-tab-item>
      <v-tab-item>
        <v-data-table
          v-bind:headers="headersEstadoCuenta"
          v-bind:items="estadoCuenta"
          disable-sort
          mobile-breakpoint="0"
        >
          <template v-slot:item.cargo="{item}">{{item.cargo | currency}}</template>
          <template v-slot:item.abono="{item}">{{item.abono | currency}}</template>
          <template v-slot:item.total_cargo="{item}">{{item.total_cargo | currency}}</template>
          <template v-slot:item.cargo_moneda_base="{item}">{{item.cargo_moneda_base | currency}}</template>
          <template v-slot:item.abono_moneda_base="{item}">{{item.abono_moneda_base | currency}}</template>
          <template v-slot:item.saldo="{item}">{{item.saldo | currency}}</template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
  import ClientesContacto from './clientes-contacto.vue'
  import ClientesDireccion from './clientes-direccion.vue'

  export default {
    data() {
      return {
        subComponent: null,
        subComponentParams: null,
        fechaInicialEstadoCuenta: this.$moment().format('YYYY-MM-01'),
        fechaFinalEstadoCuenta: this.$moment().format('YYYY-MM-DD'),
        menuFechaInicialEstadoCuenta: false,
        menuFechaFinalEstadoCuenta: false,
        cliente_id: null,
        nombre: null,
        rfc: null,
        corporativo_id: null,
        calle: null,
        no_exterior: null,
        no_interior: null,
        codigo_postal: null,
        colonia: null,
        municipio: null,
        localidad: null,
        estado: null,
        referencia: null,
        dias_credito: null,
        limite_credito: null,
        num_cta_pago: null,
        lista_precios_id: null,
        vendedor_id: null,
        cuenta_contable: null,
        num_proveedor: null,
        corporativos: [],
        listas_precios: [],
        tipos_cliente: [],
        cuentas_contables: [],
        vendedores: [],
        headersContactos: [
          {value: 'contacto_id', text: 'ID'},
          {value: 'nombres', text: 'Nombres'},
          {value: 'apellidos', text: 'Apellidos'},
          {value: 'area', text: 'Area'},
          {value: 'correo', text: 'Correo'},
          {value: 'telefono', text: 'Telefono'},
          {value: 'movil', text: 'Movil'},
          {value: 'fax', text: 'Fax'},
        ],
        contactos: [],
        headersDirecciones: [
          {value: 'direccion_id', text: 'ID'},
          {value: 'descripcion', text: 'Descripción'},
          {value: 'codigo_postal', text: 'C.P.'},
          {value: 'estatus', text: 'Estatus'},
          {value: 'ruta_id', text: 'Ruta'},
        ],
        direcciones: [],
        headersEstadoCuenta: [
          {value: 'fecha', text: 'Fecha'},
          {value: 'documento', text: 'Documento'},
          {value: 'descripcion', text: 'Referencia'},
          {value: 'cargo', text: 'Cargo', align: 'right'},
          {value: 'abono', text: 'Abono', align: 'right'},
          {value: 'total_cargo', text: 'T.C.', align: 'right'},
          {value: 'cargo_moneda_base', text: 'Cargo M.N.', align: 'right'},
          {value: 'abono_moneda_base', text: 'Abono M.N.', align: 'right'},
          {value: 'saldo', text: 'Saldo M.N.', align: 'right'},
        ],
        estadoCuenta: [],
        tab: 0,
        formValid: false,
        requiredRules: [v => !!v || 'Este campo es requerido'],
        codigoPostalRules: [v => (/\d{5}/).test(v) || 'El codigo postal es invalido']
      }
    },
    created() {
      // Cargar corporativos
      this.$reqSisnet('Lov:Lov:Lov:LoadLovFieldCorporativos', {pageSize: 1000}, this.$store.state.session)
      .then(res => {
        this.corporativos = res.records.map(row => ({
          text: row.nombre + ' - ' + row.rfc,
          value: row.cliente_id
        }))
      })

      // Cargar listas de precios
      this.$reqSisnet('Lov:Lov:Lov:LoadLovFieldListasPrecios', {pageSize: 1000}, this.$store.state.session)
      .then(res => {
        this.listas_precios = res.records.map(row => ({
          text: row.lista_precios_id + ' - ' + row.descripcion,
          value: row.lista_precios_id
        }))
      })
      
      // Cargar tipos de cliente
      this.$reqSisnet('Lov:Lov:Lov:LoadLovFieldTiposCliente', {pageSize: 1000}, this.$store.state.session)
      .then(res => {
        this.tipos_cliente = res.records.map(row => ({
          text: row.tipo_cliente_id + ' - ' + row.descripcion,
          value: row.tipo_cliente_id
        }))
      })

      // Cargar cuentas contables
      this.$reqSisnet('Lov:Lov:Lov:LoadLovFieldCuentasContables', {pageSize: 1000}, this.$store.state.session)
      .then(res => {
        this.cuentas_contables = res.records.map(row => ({
          text: row.cuenta + ' - ' + row.descripcion,
          value: row.cuenta
        }))
      })

      // Cargar vendedores
      this.$reqSisnet('Lov:Lov:Lov:LoadLovFieldVendedores', {pageSize: 1000}, this.$store.state.session)
      .then(res => {
        this.vendedores = res.records.map(row => ({
          text: row.vendedor_id + ' - ' + row.nombre,
          value: row.vendedor_id
        }))
      })

      this.cliente_id = this.params.cliente_id
      if (!this.cliente_id === null) return

      this.load()
    },
    methods: {
      load() {
        // Cargar cliente
        this.$reqSisnet('ventas:clientes:clientes:Load', {cliente_id: this.cliente_id}, this.$store.state.session)
        .then(res => {
          this.contactos = res.contactos
          
          this.nombre = res.nombre
          this.rfc = res.rfc
          this.corporativo_id = res.corporativo_id
          this.calle = res.calle
          this.no_exterior = res.no_exterior
          this.no_interior = res.no_interior
          this.codigo_postal = res.codigo_postal
          this.colonia = res.colonia
          this.municipio = res.municipio
          this.localidad = res.localidad
          this.estado = res.estado
          this.referencia = res.referencia
          this.dias_credito = res.dias_credito
          this.limite_credito = res.limite_credito
          this.num_cta_pago = res.num_cta_pago
          this.lista_precios_id = res.lista_precios_id
          this.vendedor_id = res.vendedor_id
          this.cuenta_contable = res.cuenta_contable
          this.num_proveedor = res.num_proveedor
        })

        // Cargar direcciones
        this.$reqSisnet('ventas:clientes:clientes:SearchDirecciones', {cliente_id: this.cliente_id}, this.$store.state.session)
        .then(res => {
          this.direcciones = res.records
        })
      },
      save() {
        if (!this.$refs.form.validate()) return this.$dialog.alert('Datos invalidos!', 'Por favor verifique la información')
        this.$dialog.loading()
        // Guardar cliente
        setTimeout(this.$dialog.hide, 3000)
      },
      remove() {
        this.$dialog.confirm('Alerta', 'Desea eliminar al cliente?', () => {
          this.$dialog.loading()
          // Eliminar cliente
          setTimeout(this.$dialog.hide, 3000)
        })
      },
      addContacto() {
        this.subComponentParams = {cliente_id: this.cliente_id}
        this.subComponent = ClientesContacto
      },
      openContacto(contacto_id) {
        this.subComponentParams = {cliente_id: this.cliente_id, contacto_id}
        this.subComponent = ClientesContacto
      },
      addDireccion() {
        this.subComponentParams = {cliente_id: this.cliente_id}
        this.subComponent = ClientesDireccion
      },
      openDireccion(direccion_id) {
        this.subComponentParams = {cliente_id: this.cliente_id, direccion_id}
        this.subComponent = ClientesDireccion
      },
      loadEstadoCuenta() {
        let params = {
          cliente_id: this.cliente_id,
          fecha_inicial: this.$moment(this.fechaInicialEstadoCuenta).format('DD/MM/YYYY'),
          fecha_final: this.$moment(this.fechaFinalEstadoCuenta).format('DD/MM/YYYY'),
        }
        this.$reqSisnet('ventas:clientes:clientes_estado_cuenta:SearchEstadoCuenta', params, this.$store.state.session)
        .then(res => this.estadoCuenta = res.records)
      },
      downloadPDFEstadoCuenta() {
        let params = {
          cliente_id: this.cliente_id,
          fecha_inicial: this.$moment(this.fechaInicialEstadoCuenta).format('DD/MM/YYYY'),
          fecha_final: this.$moment(this.fechaFinalEstadoCuenta).format('DD/MM/YYYY'),
        }
        window.open(this.$linkSisnet('ventas:clientes:clientes_estado_cuenta:ExportPdfEstadoCuenta', params, this.$store.state.session))
      },
      downloadExcelEstadoCuenta() {
        let params = {
          cliente_id: this.cliente_id,
          fecha_inicial: this.$moment(this.fechaInicialEstadoCuenta).format('DD/MM/YYYY'),
          fecha_final: this.$moment(this.fechaFinalEstadoCuenta).format('DD/MM/YYYY'),
        }
        window.open(this.$linkSisnet('ventas:clientes:clientes_estado_cuenta:ExportExcelEstadoCuenta', params, this.$store.state.session))
      },
      clearFilter() {
        this.fechaInicialEstadoCuenta = this.$moment().format('YYYY-01-01')
        this.fechaFinalEstadoCuenta = this.$moment().format('YYYY-MM-DD')
        this.loadEstadoCuenta()
      },
      close() {
        this.$parent.subComponent = null
      }
    },
    props: ['params'],
    components: {
      ClientesContacto,
      ClientesDireccion
    }
  }
</script>