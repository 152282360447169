<template>
  <span>
    <Container v-if="$store.state.session"></Container>
    <Login v-else></Login>
    <global-dialog></global-dialog>
  </span>
</template>

<script>
  import Login from './views/Login/Login.vue'
  import Container from './views/Container/Container.vue'

  export default {
    name: 'App',
    data() {
      return {}
    },
    components: {
      Login,
      Container
    }
  }
</script>
