<template>
  <component
    v-if="!!subComponent"
    v-bind:is="subComponent"
    v-bind:params="subComponentParams"
  ></component>
  <div v-else>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      right
    >
      <v-list>
        <v-list-item v-on:click="showFileUpload = true; drawer = false">
          <v-list-item-icon>
            <v-icon>mdi-upload</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Importar</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-card>
      <v-toolbar color="secondary">
        <v-text-field
          type="search"
          clearable
          v-model="searchValue"
          v-on:keydown.enter="load"
          v-on:click:clear="() => {searchValue = ''; load()}"
          prepend-inner-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
          solo
          dense
        ></v-text-field>
        <v-btn icon v-on:click="showDrawer = true" v-bind:loading="isLoading" color="white">
          <v-icon v-on:click="load">mdi-sync</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn icon color="white" v-on:click="downloadPDF">
          <v-icon>mdi-file-pdf-box</v-icon>
        </v-btn>
        <v-btn icon color="white" v-on:click="downloadExcel">
          <v-icon>mdi-microsoft-excel</v-icon>
        </v-btn>
        <v-btn icon color="white" v-on:click="add">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn
            icon
            v-on:click="drawer = !drawer"
            color="white"
          >
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-data-table
        fixed-header
        v-bind:headers="headers"
        v-bind:items="data"
        v-bind:server-items-length="itemsLength"
        v-bind:options.sync="options"
        v-bind:footer-props="{'items-per-page-options': [100, 50, 25]}"
        v-bind:loading = "isLoading"
        v-on:click:row="row => open(row.proveedor_id)"
        v-on:update:options="load"
      ></v-data-table>
    </v-card>
    <FileUpload
      v-bind:show="showFileUpload"
      v-bind:files="files"
      v-on:send="upload"
      v-on:close="showFileUpload = false"
      ></FileUpload>
  </div>
</template>

<script>
  import ProveedoresDetalle from './proveedores-detalle.vue'

  import FileUpload from '../../../components/FileUpload.vue'

  export default {
    data() {
      return {
        drawer: false,
        subComponent: null,
        subComponentParams: null,
        isLoading: false,
        headers: [
          {value: 'proveedor_id', text: 'ID'},
          {value: 'nombre', text: 'Nombre'},
          {value: 'rfc', text: 'RFC'},
          {value: 'estatus', text: 'Estatus'}
        ],
        data: [],
        options: {},
        itemsLength: 0,
        searchValue: '',
        showFileUpload: false,
        files: [
          {
            name: 'archivo_catalogo',
            label: 'Catálogo',
            required: true,
            accept: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }
        ]
      }
    },
    methods: {
      load() {
        this.isLoading = true
        this.$reqSisnet('compras:proveedores:proveedores:Search', this.params, this.$store.state.session)
        .then(res => {
          if (!res.records) throw new Error(res.msg || 'Imposible cargar registros')
          this.itemsLength = res.totalCount
          this.data = res.records
          this.isLoading = false
        })
        .catch(err => {
          this.isLoading = false
          this.$handleError(err)
        })
      },
      add() {
        this.subComponentParams = {}
        this.subComponent = ProveedoresDetalle
      },
      open(proveedor_id) {
        this.subComponentParams = {proveedor_id}
        this.subComponent = ProveedoresDetalle
      },
      upload() {
        
      },
      downloadPDF() {
        window.open(this.$linkSisnet('compras:proveedores:proveedores:ExportPdf', this.params, this.$store.state.session))
      },
      downloadExcel() {
        window.open(this.$linkSisnet('compras:proveedores:proveedores:ExportExcel', this.params, this.$store.state.session))
      }//,
      //downloadContactosExcel() {
        //window.open(this.$linkSisnet('compras:proveedores:proveedores:ExportExcelContactos', this.params, this.$store.state.session))
      //}
    },
    computed: {
      params() {
        let params = {
          nombre: this.searchValue || '',
          pageSize: this.options.itemsPerPage,
          start: (this.options.page - 1) * this.options.itemsPerPage
        }
        if (this.options.sortBy[0]) {
          params.sort = this.options.sortBy[0],
          params.dir = this.options.sortDesc[0] ? 'DESC' : 'ASC'
        }
        return params
      }
    },
    components: {
      ProveedoresDetalle,
      FileUpload
    }
  }
</script>
