import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/src/locale/es.ts'


Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#284697',
        secondary: '#1e1e1e',
        info: '#eae7e4',
        danger: '#ff5436'
      },
      dark: {
        primary: '#284697',
        secondary: '#1e1e1e',
        info: '#eae7e4',
        danger: '#ff5436'
      },
    },
  },
  lang: {
    locales: {es},
    current: 'es'
  }
});
