<template>
  <v-app>
    <v-app-bar dark color="danger" app>
      <v-app-bar-nav-icon v-on:click="drawerOpen"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-md-h5 text-subtitle-1">{{this.$route.meta.title ? this.$route.meta.title : title}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title class="text-center pb-2">
              {{$store.state.usuario}}
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item v-on:click="$dialog.alert('Error', 'La función no está disponible')">
            <v-list-item-icon>
              <v-icon>mdi-domain</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Cambiar Sucursal
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-on:click="logout">
            <v-list-item-icon>
              <v-icon color="danger">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Cerrar Sesión
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      app
    >
      <router-link
        v-if="options.length == 1"
        to="/"
        style="text-decoration: none; color: inherit;"
      >
        <v-list-item class="py-2">
          <v-list-item-avatar>
            <v-img src="../../img/app-icon.png"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-h6">Planator</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content class="text-center">
            <v-list-item-title class="text-caption font-weight-bold text-wrap pb-2">
              {{$store.state.empresa}}
            </v-list-item-title>
            <v-list-item-subtitle class="text-caption text-wrap">
              {{$store.state.sucursal}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </router-link>
      <v-list-item v-else>
        <v-btn icon large v-on:click="drawerBack">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-list-item-content>
          <v-list-item-title>{{selectedOption.text}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list>
        <v-list-item
          v-for="(option, i) in selectedOption.children"
          v-bind:key="i"
          v-on:click="select(option)"
          link
        >
          <v-list-item-content>
            <v-list-item-title>{{option.text}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import config from '../../js/config.js'
  
  export default {
    data: function() {
      return {
        title: '',
        drawer: false,
        options: []
      }
    },
    created() {
      this.$reqSisnet('seguri:acceso:acceso_jwt:LoadMenu', {}, this.$store.state.session)
      .then(res => {
        if (!res.record) throw new Error(res.msg || 'Imposible cargar menú')
        let filterMenu = branch => {
          if (branch.children?.length) {
            let newChildren = branch.children.map(filterMenu).filter(child => child)
            if (!newChildren.length) return false
            branch.children = newChildren
            return branch
          }
          if (!branch.command) return false
          if (!config.options.includes(branch.idopcion)) return false
          return branch
        }
        this.options = [filterMenu(res.record)]
      })
      .catch(this.$handleError)
    },
    methods: {
      drawerOpen() {
        this.options = [this.options[0]]
        this.drawer = true
      },
      drawerBack() {
        if (this.options.length > 1) this.options.pop()
      },
      select(option) {
        if (!option.command) return this.options.push(option)

        this.$reqSisnet('seguri:acceso:acceso_jwt:LoadProgram', {option: option.command}, this.$store.state.session)
        .then(res => {
          this.title = res.description.split('->').pop()
          this.$router.push({path: `/${res.module}-${res.view}`})
          .catch(() => {})
        })

        this.drawer = false
      },
      logout() {
        this.$dialog.confirm('Alerta', 'Desea salir del sistema?', () => {
          this.$store.commit('destroySession')
          window.location = '/'
        })
      }
    },
    computed: {
      selectedOption() {
        return this.options[this.options.length - 1] || {}
      }
    }
  }
</script>