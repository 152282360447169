<template>
  <v-app>
    <v-main>
      <v-container>
        <v-card max-width="500px" class="mx-auto my-12">
          <v-img src="../../img/logo.png"></v-img>
          <v-card-text>
            <v-text-field
              type="text"
              v-model="username"
              placeholder="Usuario"
              prepend-icon="mdi-account"
              v-on:keyup.enter="$refs.password.focus"
              v-on:blur="loadSucursales"
            ></v-text-field>
            <v-text-field
              ref="password"
              type="password"
              v-model="password"
              placeholder="Contraseña"
              prepend-icon="mdi-lock"
              v-on:keyup.enter="$refs.sucursales.focus"
            ></v-text-field>
            <v-select
              ref="sucursales"
              v-model="sucursal"
              v-bind:items="sucursales"
              label="Sucursal"
              prepend-icon="mdi-office-building"
            ></v-select>
            <v-btn block
              color="secondary"
              v-on:click="login"
              v-bind:disabled="!(this.username && this.password && this.sucursal)"
            >Continuar</v-btn>
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import config from '../../js/config.js'

  export default {
    data: function () {
      return {
        username: null,
        password: null,
        sucursal: null,
        sucursales: []
      }
    },
    methods: {
      loadSucursales: function() {
        if (!this.username) return this.sucursales = []

        this.$reqSisnet('seguri:acceso:acceso_jwt:SearchSucursalesUsuario', {usuario_id: this.username})
        .then(res => {
          if (!res.record.length) throw new Error(res.msg || 'Imposible cargar las sucursales')
          
          this.sucursales = res.record.map(row => ({
            text: row.descripcion,
            value: row.empresa_sucursal_id
          }))
          this.sucursal = this.sucursales[0].value
        })
        .catch(this.$handleError)
      },
      login: function() {
        this.$reqSisnet('seguri:acceso:acceso_jwt:Login', {
          usuario: this.username,
          contrasena: this.password,
          sucursal: this.sucursal,
          workspace: config.workspace
        })
        .then(res => {
          if (!res.success) throw new Error(res.msg)

          this.$store.commit('createSession', res)
        })
        .catch(this.$handleError)
      }
    }
  }
</script>