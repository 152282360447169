import Home from './views/Home/Home.vue'
import NotFound from './views/NotFound/NotFound.vue'

import Clientes from './modules/ventas/clientes/clientes.vue'
import ReporteAntigSaldosDetalle from './modules/ventas/reporte_antig_saldos_detalle/reporte_antig_saldos_detalle.vue'
import Proveedores from './modules/compras/proveedores/proveedores.vue'
import ReporteAntigSaldosCmpraDet from './modules/compras/reporte_antig_saldos_cmpra_det/reporte_antig_saldos_cmpra_det.vue'
import CatalogoInventarios from './modules/inventarios/catalogo_inventarios/catalogo_inventarios.vue'
import ReporteExistencias from './modules/inventarios/reporte_existencias/reporte_existencias.vue'

export default [
  {path: '/', component: Home, meta: {title: 'Inicio'}},
  {path: '/ventas-clientes', component: Clientes, meta: {title: 'Catálogo de Clientes'}},
  {path: '/ventas-reporte_antig_saldos_detalle', component: ReporteAntigSaldosDetalle, meta: {title: 'Detalle de Antigüedad de Saldos'}},
  {path: '/compras-proveedores', component: Proveedores, meta: {title: 'Catálogo de Proveedores'}},
  {path: '/compras-reporte_antig_saldos_cmpra_det', component: ReporteAntigSaldosCmpraDet, meta: {title: 'Detalle de Antigüedad de Saldos'}},
  {path: '/inventarios-catalogo_inventarios', component: CatalogoInventarios, meta: {title: 'Catálogo de Inventarios'}},
  {path: '/inventarios-reporte_existencias', component: ReporteExistencias, meta: {title: 'Reporte de Existencias'}},
  {path: '*', component: NotFound},
]