import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify'
import routes from './routes'
import App from './App.vue'
import Helper from './plugins/helper'
import GlobalDialog from './plugins/dialog/dialog.js'
import VueMoment from 'vue-moment'
import VueNumeralFilter from 'vue-numeral-filter'

import './css/index.css'

Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(Helper)
Vue.use(GlobalDialog)
Vue.use(VueMoment)
Vue.use(VueNumeralFilter, {locale: ''})

const router = new VueRouter({routes})

const store = new Vuex.Store({
  state: {
    session: localStorage.getItem('session'),
    usuario: localStorage.getItem('usuario'),
    empresa: localStorage.getItem('empresa'),
    sucursal: localStorage.getItem('sucursal')
  },
  mutations: {
    createSession: function(state, data) {
      state.session = data.session
      state.usuario = data.usuario
      state.empresa = data.empresa
      state.sucursal = data.sucursal

      localStorage.setItem('session', data.session)
      localStorage.setItem('usuario', data.usuario)
      localStorage.setItem('empresa', data.empresa)
      localStorage.setItem('sucursal', data.sucursal)
    },
    destroySession: function(state) {
      state.session = null
      state.session = null
      state.session = null
      state.session = null
      localStorage.clear()
    }
  }
})

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
