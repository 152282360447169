<template>
  <component
    v-if="!!subComponent"
    v-bind:is="subComponent"
    v-bind:params="subComponentParams"
  ></component>
  <v-card v-else>
    <v-toolbar background-color="dark" dark>
      <v-btn icon v-on:click="close">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon color="danger" v-on:click="remove">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn icon v-on:click="save">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card class="pa-4">
      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="4" sm="4">
              <v-text-field
                v-model="origen_id"
                label="Id"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field
                v-model="tipo"
                label="Tipo"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="8" sm="8">
              <v-text-field
                v-model="descripcion"
                label="Descripción"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field
                v-model="telefono"
                label="Telefono"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
  export default {
    data() {
      return {
        proveedor_id: null,
        origen_id: null,
        tipo: null,
        descripcion: null,
        telefono: null
      }
    },
    created() {
      this.proveedor_id = this.params.proveedor_id
      this.origen_id = this.params.origen_id

      if (!this.proveedor_id) this.$dialog.alert('Error', 'No se encontró el registro especificado', this.close)
      if (this.origen_id === null) return

      this.load()
    },
    methods: {
      load() {
        this.$reqSisnet('compras:proveedores:proveedores:SearchOrigen', {proveedor_id: this.proveedor_id}, this.$store.state.session)
        .then(res => {
          let origenes = res.records.find(d => d.origen_id == this.origen_id)
          
          this.tipo = origenes.tipo
          this.descripcion = origenes.descripcion
          this.telefono = origenes.telefono
        })
      },
      save() {

      },
      remove() {
        this.$dialog.confirm('Alerta', 'Desea eliminar el registro?', () => {
          this.$dialog.loading()
          window.setTimeout(() => this.$dialog.alert('Error!', 'No se pudo completar la operacion'), 5000)
        })
      },
      close() {
        this.$parent.subComponent = null
      }
    },
    props: ['params']
  }
</script>