<template>
  <v-card>
    <v-toolbar background-color="dark" dark>
      <v-btn icon v-on:click="close">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon color="danger" v-on:click="remove">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn icon v-on:click="save">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card class="pa-4">
      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="nombres"
                label="Nombres"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="apellidos"
                label="Apellidos"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="area"
                label="Area"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="correo"
                label="Correo"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="2">
              <v-text-field
                v-model="telefono"
                label="Teléfono"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="2">
              <v-text-field
                v-model="movil"
                label="Movil"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                v-model="fax"
                label="Fax"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
  export default {
    data() {
      return {
        cliente_id: null,
        contacto_id: null,
        area: null,
        nombres: null,
        apellidos: null,
        telefono: null,
        movil: null,
        fax: null,
        correo: null
      }
    },
    created() {
      this.cliente_id = this.params.cliente_id
      this.contacto_id = this.params.contacto_id

      if (!this.cliente_id) this.$dialog.alert('Error', 'No se encontró el registro especificado', this.close)
      if (this.contacto_id === null) return

      this.load()
    },
    methods: {
      load() {
        this.$reqSisnet('ventas:clientes:clientes:Load', {cliente_id: this.cliente_id}, this.$store.state.session)
        .then(res => {
          let contacto = res.contactos.find(c => c.contacto_id == this.contacto_id)

          this.area = contacto.area
          this.nombres = contacto.nombres
          this.apellidos = contacto.apellidos
          this.telefono = contacto.telefono
          this.movil = contacto.movil
          this.fax = contacto.fax
          this.correo = contacto.correo
        })
      },
      save() {
        this.$dialog.confirm('Alerta', 'Desea guardar los cambios?', () => {
          this.$reqSisnet('ventas:clientes:clientes:SaveContacto', {cliente_id: this.cliente_id}, this.$store.state.session)
          .then(res => {
            this.direcciones = res.records
          })
        })
      },
      remove() {
        this.$dialog.confirm('Alerta', 'Desea eliminar el contacto?', () => {
          let params = {
            cliente_id: this.cliente_id,
            contacto_id: this.contacto_id
          }
          this.$reqSisnet('ventas:clientes:clientes:DeleteContacto', params, this.$store.state.session)
          .then(res => {
            this.$dialog.alert('Mensage', res.msg, this.close)
          })
        })
      },
      close() {
        this.$parent.subComponent = null
      }
    },
    props: ['params']
  }
</script>