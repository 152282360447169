import config from '../js/config.js'

export default {
  install(Vue) {
    Vue.prototype.$reqSisnet = async function(option, params = {}, session, method = 'POST') {
      params['opReq'] = option
      params['session'] = session
      let body = new FormData()
      Object.keys(params).forEach(key => {
        params[key] = (params[key] || params[key] === 0) ? params[key] : ''
        if (Array.isArray(params[key])) {
          params[key].forEach(value => body.append(key + '[]', value))
        }
        else {
          body.append(key, params[key])
        }
      })
      let res = await fetch(config.api_url, {method, body})
      res = await res.text()
      try {
        res = JSON.parse(res)
      }
      catch(err) {
        console.log(err)
      }
      return res
    }
    Vue.prototype.$linkSisnet = function(option, params = {}, session) {
      params['opReq'] = option
      params['session'] = session
      let query = []
      Object.keys(params).forEach(key => {
        params[key] = (params[key] || params[key] === 0) ? params[key] : ''
        if (Array.isArray(params[key])) {
          params[key].forEach(value => query.push(`${key}[]=${value}`))
        }
        else {
          query.push(`${key}=${params[key]}`)
        }
      })
      query = query.join('&')
      return `${config.api_url}?${query}`
    }
    Vue.prototype.$handleError = function(err) {
      Vue.prototype.$dialog.alert('Error!', err.message)
    }
  }
}