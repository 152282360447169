<template>
  <component
    v-if="!!subComponent"
    v-bind:is="subComponent"
    v-bind:params="subComponentParams"
  ></component>
  <v-card v-else>
    <v-toolbar background-color="dark" dark>
      <v-btn icon v-on:click="close">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon color="danger" v-on:click="remove">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn icon v-on:click="save">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card class="pa-4">
      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="4" sm="4">
              <v-text-field
                v-model="direccion_id"
                label="Id"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="8" sm="8">
              <v-text-field
                v-model="descripcion"
                label="Descripción"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field
                v-model="calle"
                label="Calle"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field
                v-model="colonia"
                label="Colonia"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field
                v-model="estado"
                label="Estado"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field
                v-model="municipio"
                label="Municipio"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
  export default {
    data() {
      return {
        cliente_id: null,
        direccion_id: null,
        descripcion: null,
        calle: null,
        no_interior: null,
        no_exterior: null,
        colonia: null,
        localidad: null,
        referencia: null,
        municipio: null,
        estado: null,
        pais: null,
        codigo_postal: null,
        estatus: null,
        c_estado: null,
        c_municipio: null,
        c_localidad: null,
        c_colonia: null,
        ruta_id: null
      }
    },
    created() {
      this.cliente_id = this.params.cliente_id
      this.direccion_id = this.params.direccion_id

      if (!this.cliente_id) this.$dialog.alert('Error', 'No se encontró el registro especificado', this.close)
      if (this.direccion_id === null) return

      this.load()
    },
    methods: {
      load() {
        this.$reqSisnet('ventas:clientes:clientes:SearchDirecciones', {cliente_id: this.cliente_id}, this.$store.state.session)
        .then(res => {
          let direccion = res.records.find(d => d.direccion_id == this.direccion_id)
          
          this.descripcion = direccion.descripcion
          this.calle = direccion.calle
          this.no_interior = direccion.no_interior
          this.no_exterior = direccion.no_exterior
          this.colonia = direccion.colonia
          this.localidad = direccion.localidad
          this.referencia = direccion.referencia
          this.municipio = direccion.municipio
          this.estado = direccion.estado
          this.pais = direccion.pais
          this.codigo_postal = direccion.codigo_postal
          this.estatus = direccion.estatus
          this.c_estado = direccion.c_estado
          this.c_municipio = direccion.c_municipio
          this.c_localidad = direccion.c_localidad
          this.c_colonia = direccion.c_colonia
          this.ruta_id = direccion.ruta_id
        })
      },
      save() {

      },
      remove() {
        this.$dialog.confirm('Alerta', 'Desea eliminar la direccion?', () => {
          this.$dialog.loading()
          window.setTimeout(() => this.$dialog.alert('Error!', 'No se pudo completar la operacion'), 5000)
        })
      },
      close() {
        this.$parent.subComponent = null
      }
    },
    props: ['params']
  }
</script>