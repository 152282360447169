export default ({
  //api_url: 'SisnetV3Desarrollo/php/interfase_jwt.php',
  //api_url: '/php/interfase_jwt.php',
  //api_url: 'https://test-sv3.dscorp.com.mx/php/interfase_jwt.php',

  api_url: 'https://erp.planator.mx/php/interfase_jwt.php',
  workspace: 'default',
  options: [
    'CAT_CLIENTES_01',
    'DOC_FACTURAS_VENTA_02',
    'REP_ANTIG_SALDOS_02',
    'CAT_INVENTARIOS_01',
    'REP_EXISTENCIAS_01',
    'CAT_PROVEEDORES_01',
    'REP_ANTIG_SALDOS_CMPR_02'
  ]
})